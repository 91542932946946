import { Container } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import PageTitle from '../components/coreLayout/PageTitle';
import FleetItemDisplay from '../components/features/FleetItemDisplay';

export interface Facts {
    readonly speed: string;
    readonly seats: string;
    readonly power: string;
}

export interface FleetImage {
    readonly src: string;
    readonly fallback: string;
    readonly alt: string;
}

export interface FleetItem {
    readonly name: string;
    readonly description: string;
    readonly keyFacts: Facts;
    readonly images: FleetImage[];
}

const fleetPageStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        paddingBottom: 50,
        display: 'flex',
        flexDirection: 'column'
    },
    backgroundTransition: {
        background: ` -webkit-linear-gradient(
            270deg
            , ${theme.palette.background.default} 0%, ${theme.palette.background.subtle} 50%, ${theme.palette.background.default} 100%)`
    },
    backgroundColor: {
        backgroundColor: theme.palette.background.subtle,
        [theme.breakpoints.only('xs')]: {
            backgroundColor: 'unset'
        }
    }
}));

export default function FleetPage() {
    const { classes } = fleetPageStyles();
    const fleet: FleetItem[] = [
        {
            name: 'Pina Colada',
            description: `Built in 2019, Pina Colada is the perfect vessel for a day trip in the Whitsundays. 
            She has an open deck design for great visibility, but can also be protected from the weather if needed.
            The catamaran design creates plenty of space on-board, and she handles the Whitsunday conditions with ease.
            The on-board kiosk is perfect for keeping hydrated through-out the day, and boiling water on board means you can enjoy a cup of tea or coffee after a swim.
            Featuring a front boarding ramp, beach access is made simple, and the snorkel platform at the rear makes it easy to get in and out of the water.
            Pina Colada is a truly versatile vessel, perfectly suited for Whitsunday Island adventures, snorkelling, and beach access.
            Pina Colada is in Class C survery, for 50 passengers + 4 crew.`,
            keyFacts: {
                speed: '22 - 25 knots',
                seats: 'Up to 50 passengers',
                power: '4x 300hp Outboard'
            },
            images: [
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/WhitsundaysTourAirlieBeach.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/WhitsundaysTourAirlieBeach.jpg',
                    alt: 'Charter boat to Whitehaven Beach in the Whitsundays'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/AirlieBeachGSLMarine.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/AirlieBeachGSLMarine.jpg',
                    alt: 'Whitsunday tour boat, from Airlie Beach to Whitehaven Beach'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/AirlieBeachCharterBoat.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/AirlieBeachCharterBoat.jpg',
                    alt: 'Airlie Beach day boat to Whitehaven Beach'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/AirlieBeachWhitehavenBoat.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/AirlieBeachWhitehavenBoat.jpg',
                    alt: 'Whitsunday Island boat tour - GSL Marine'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/pinaColadaWhitsundayTour.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/pinaColadaWhitsundayTour.jpg',
                    alt: 'Airlie Beach tour to Whitehaven Beach in the Whitsundays'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/WhitehavenTourGSL.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslmarine/vessels/WhitehavenTourGSL.jpg',
                    alt: 'Travelling to Whitehaven Beach from Airlie Beach'
                }
            ]
        }
    ];

    return (
        <>
            <Container maxWidth="lg">
                <PageTitle title="Our Fleet" tagline="Cruising the Whitsunday Islands" />
            </Container>
            <div style={{ paddingTop: 38 }}>
                {fleet.map((item, i) => (
                    <div
                        key={`fleetItem${i + 1}`}
                        className={clsx(classes.container, {
                            [classes.backgroundTransition]: i === 0,
                            [classes.backgroundColor]: (i + 1) % 2 === 0
                        })}
                    >
                        <FleetItemDisplay asset={item} reverse={(i + 1) % 2 === 0} />
                    </div>
                ))}
            </div>
        </>
    );
}

/*

,
        {
            name: 'Water Rat',
            description: `A capable off-shore rib, used by GSL Marine on charter, and to support our operations in the Whitsundays.
            Water Rat is in Class C survey, for 8 passengers and 2 crew.
            It is also used for charter in the Whitsundays, including private tours, support for larger vessels and superyachts, as well as towing and crew transport.
            Fitted with 2 150hp Yamaha's, she is capable of travelling at over 40 knots!
            The Gemini fibreglass hull, makes for a smooth and enjoyable ride.
            Water Rat is fitted with a large bench seat, and the cushions can be removed to covert this to a utility space for carrying gear.`,
            keyFacts: {
                speed: '100 knots',
                seats: 'Up to 7 passengers',
                power: '450hp Pratt & Whitney R-985'
            },
            images: [
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/seaplaneWhitsundayTakeOff16x9.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/seaplaneWhitsundayTakeOff16x9.jpg',
                    alt: 'De Havilland Beaver take off from Airlie Beach in the Whitsundays'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/beaverseaplanewhitehaven16x9.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/beaverseaplanewhitehaven16x9.jpg',
                    alt: 'De Havilland Beaver at Whitehaven Beach in the Whitsundays'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/beaveramphibwhitsundays16x9.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/beaveramphibwhitsundays16x9.jpg',
                    alt: 'Amphibious De Havilland Beaver Whitsundays'
                },

                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/dhc2BeaverTakeOff16x9.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/dhc2BeaverTakeOff16x9.jpg',
                    alt: 'The Cessna Caravan floatplane operating to the Great Barrier Reef from Airlie Beach'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/seaplaneWhitehavenBeach16x9.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/airwhitsunday/aircraft/seaplaneWhitehavenBeach16x9.jpg',
                    alt: 'Cessna Caravan seaplane in the Whitsundays'
                }
            ]
        }

*/
