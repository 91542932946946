import { Box, Container, Typography, useTheme } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import HeroSection from '../components/features/HeroSection';
import InstagramImages from '../components/features/InstagramImages';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { baseProducts } from '../utils/stores/productCodes';

const HomePage = () => {
    const theme = useTheme();
    const heroTiles = baseProducts
        .map((prod) => prod.tags.includes('featureTile') && prod.productCode)
        .filter((res) => res);

    const Title = (props: TypographyProps) => (
        <Typography
            sx={{
                fontSize: { xs: 30, md: 38 },
                mt: { xs: 3, md: 1 },
                color: theme.palette.primary.main,
                lineHeight: '115%',
                letterSpacing: '-1px',
                fontWeight: 400,
                pb: 2,

                textAlign: 'center'
            }}
        >
            {props.children}
        </Typography>
    );

    const Body = (props: TypographyProps) => (
        <Typography
            sx={{
                fontSize: { xs: 16, md: 18 },
                opacity: 0.8,
                fontWeight: 400,
                pb: 2,
                maxWidth: { xs: 0.9, md: 0.75 },
                textAlign: 'center'
            }}
        >
            {props.children}
        </Typography>
    );

    return (
        <>
            <Helmet>
                <title>Airlie Beach Jet Ski Tours | Whitehaven Beach Tours | GSL Marine</title>
                <meta
                    name="description"
                    content="GSL Marine offer jet ski tours and Whitehaven Beach cruises in Airlie Beach."
                />
            </Helmet>
            <HeroSection productCodes={heroTiles} />
            <Box
                sx={{
                    background: ` -webkit-linear-gradient(rgba(255, 255, 255, 1), ${theme.palette.background.subtle})`,
                    width: 1
                }}
            >
                <Container maxWidth="lg" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Title>Airlie Beach Jet Ski Tours & Whitehaven Adventures</Title>
                    <Body>
                        Departing from Airlie Beach, GSL Marine offer daily jet ski tours, from our base at Port of
                        Airlie Marina. Only a short walk from town centre, our comfortable lounge is a great place to
                        start your fun-filled jet ski adventure in Airlie Beach. Our hire skis are a great way to see
                        the Whitsundays, at full throttle! Drive your own jet-ski on a guided tour, with no licence or
                        experience required! Our tour guides provide all the training and assistance you need, to have a
                        great time on the water.
                    </Body>
                    <Body>
                        Also departing daily from Airlie Beach, our Whitehaven Beach cruise is a great way to explore
                        the Whitsundays, and visit one of the most famous beaches in Australia. Swim, snorkel, bushwalk,
                        or just relax on the beach, and enjoy the Whitsundays at it&apos;s finest.
                    </Body>
                </Container>
            </Box>
            <ProductDisplayGrid backgroundColor tag={'popular'} />
            <Box
                sx={{
                    width: 1,
                    backgroundColor: 'rgba(11, 128, 196, 0.05)',
                    m: 0,
                    maxWidth: 'unset',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Box
                    maxWidth="lg"
                    sx={{
                        width: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Title>Partner Products</Title>
                    <Body>
                        Explore further, with other Whitsunday products. Airlie Beach has a range of tours and
                        experiences, and some of our partners can offer alternative experiences in the Whitsundays.
                    </Body>
                </Box>
            </Box>
            <ProductDisplayGrid backgroundColor tag={'other'} />
            <InstagramImages />
        </>
    );
};

export default HomePage;
