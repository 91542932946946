import { Route, Routes } from 'react-router-dom';

import ProductTemplate from '../components/products/ProductTemplate';
import BookingConfirmationPage from '../pages/BookingConfirmationPage';
import Contact from '../pages/Contact';
import FleetPage from '../pages/FleetPage';
import Homepage from '../pages/Homepage';
import PageNotFound from '../pages/PageNotFound';
import TermsAndConditions from '../pages/TermsAndConditions';

export enum PATH_IDS {
    PRODUCT_NAME = 'productName'
}

export const routes = {
    products: (productName: string) => `/products/${productName}`
};

export default function Pages() {
    return (
        <Routes>
            <Route path={routes.products(':' + PATH_IDS.PRODUCT_NAME)} Component={ProductTemplate} />
            <Route path="/" Component={Homepage} />
            <Route path="/confirmation/:ref" Component={BookingConfirmationPage} />
            <Route path="/contact-us" Component={Contact} />
            <Route path="/terms-and-conditions" Component={TermsAndConditions} />
            <Route path="/fleet" Component={FleetPage} />
            <Route path="/404" Component={PageNotFound} />
            <Route path="*" Component={PageNotFound} />
        </Routes>
    );
}
