import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const colors = {
    light: 'rgba(178, 224, 251, 1)',
    main: 'rgba(11, 128, 196, 1)',
    dark: 'rgba(6, 63, 96, 1)',
    bright: 'rgba(22, 193, 227, 1)'
};

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        subtle: string;
    }
    interface TypeText {
        soft: string;
        info: string;
    }
}

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            common: {
                black: '#000',
                white: '#fff'
            },
            background: {
                paper: '#fff',
                default: 'rgba(255, 255, 255, 1)',
                subtle: 'rgba(11, 128, 196, 0.05)'
            },
            primary: {
                main: colors.main,
                light: colors.light,
                dark: colors.dark,
                contrastText: '#fff'
            },
            secondary: {
                main: colors.bright,
                light: 'rgb(212 224 254)',
                contrastText: '#b3b3b3'
            },
            error: {
                main: '#f44336',
                contrastText: '#fff'
            },
            text: {
                primary: '#333',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                soft: '#505160',
                info: 'rgba(0, 0, 0, 0.38)'
            }
        }
    })
);

export const gradients = {
    main: `linear-gradient(90deg, ${colors.main} 0%, ${colors.bright} 40%, ${colors.bright} 70%, ${colors.main} 100%)`,
    circular: `radial-gradient(circle, ${colors.bright} 0%, ${colors.bright} 20%, ${colors.main} 100%)`,
    background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.subtle} 100%)`
};