import EventBusyIcon from '@mui/icons-material/EventBusy';
import UpdateIcon from '@mui/icons-material/Update';
import { Container, Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from 'tss-react/mui';
import { displayCancellation } from '../../utils/functions/common';
import { Product } from '../../utils/models/products';
import { BookingStore } from '../../utils/stores/BookingStore';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import BookingModal from '../bookings/BookingModal';
import PageTitle from '../coreLayout/PageTitle';
import KeyDetailWidget from './KeyDetailWidget';
import KeySellingPointsWidget from './KeySellingPoints';
import ProductImageGallery from './ProductImageGallery';
import { DisplayText, Sales } from './TextAndPriceDisplay';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const displayProductStyles = makeStyles()((theme) => ({
    productContainer: {
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    productDesc: {
        [theme.breakpoints.only('xs')]: {
            border: 'none',
            textAlign: 'left'
        },
        borderRadius: 10
    },
    descriptionBox: {
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    pageTitle: {
        [theme.breakpoints.only('xs')]: {
            padding: '2rem 20px 0'
        }
    },
    divider: {
        textAlign: 'left',
        marginLeft: 10,
        width: '90%',
        marginTop: 30,
        marginBottom: 30,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    innerContainer: {
        [theme.breakpoints.only('xs')]: {
            padding: 0
        }
    },
    topContainer: {
        flexDirection: 'column',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column-reverse'
        }
    },
    cancellationtText: {
        color: theme.palette.text.soft
    },
    tourMap: {
        marginBottom: 30,
        borderRadius: 10,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        },
        [theme.breakpoints.only('xs')]: {
            borderRadius: 0,
            marginBottom: 0
        }
    }
}));

interface ProductDisplayProps {
    readonly tour: Product;
}

export default function ProductDisplay({ tour }: ProductDisplayProps) {
    const { classes } = displayProductStyles();
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);

    const currentSales = () => {
        const sales =
            productController?.product?.sales &&
            productController.product.sales.filter((sale) => !moment('').isAfter(sale.endDate));
        const returnValue = sales && sales.length > 0 ? sales : undefined;
        return returnValue;
    };

    useEffect(() => {
        return () => {
            bookingController.clearController();
            productController.clearController();
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${tour.name} | ${tour.titleSEO}`}</title>
                <meta name="description" content={tour.shortDescription} />
            </Helmet>
            <Container maxWidth="lg" sx={{ p: { xs: 0 } }}>
                <Grid container className={classes.topContainer}>
                    <Grid item xs={12} className={classes.pageTitle}>
                        <PageTitle title={tour.name} tagline={tour.tagline} />
                    </Grid>
                    <Grid item xs={12}>
                        <ProductImageGallery />
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.productContainer}>
                    <Grid item xs={12} sx={{ px: { xs: 0, lg: 2 }, maxWidth: { xs: 1, lg: 'calc(80% + 30px)' } }}>
                        <BookingModal />
                    </Grid>
                    {currentSales() && <Sales currentSales={currentSales} />}
                    <Grid
                        container
                        justifyContent="center"
                        sx={{
                            pb: { xs: 2, md: 3.5 },
                            pt: { xs: 1, md: 0 },
                            px: { xs: 2, sm: 0 },
                            maxWidth: { xs: 1, lg: 'calc(80% + 30px)' }
                        }}
                    >
                        <Grid item xs={12} sm={6}>
                            <Grid
                                item
                                sx={{
                                    py: 0,
                                    height: 1
                                }}
                            >
                                <KeyDetailWidget />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ height: '100%', width: '100%' }}>
                            <Grid
                                item
                                sx={{
                                    py: 0,
                                    height: 1
                                }}
                            >
                                <KeySellingPointsWidget />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            pb: { xs: 2, sm: 2, md: 4 },
                            maxWidth: { xs: 1, lg: 'calc(80% + 30px)' },
                            width: { xs: 1, sm: 1 },
                            pt: { xs: 0 },
                            px: { xs: 0, sm: '15px' }
                        }}
                    >
                        <Paper
                            variant="outlined"
                            className={classes.productDesc}
                            sx={{ width: 1, py: { xs: 0, sm: 2 }, px: { xs: 2, sm: 2 } }}
                        >
                            <Grid container sx={{ mb: 1 }}>
                                <Grid item xs="auto" sx={{ mr: 1.5, pt: 0.25 }}>
                                    <EventBusyIcon color="primary" />
                                </Grid>
                                <Grid container direction="column" item xs={true}>
                                    <Typography sx={{ fontWeight: 500, marginBottom: 1, fontSize: 18 }}>
                                        Free Cancellation
                                    </Typography>
                                    <Typography sx={{ maskBorderWidth: 1 }} className={classes.cancellationtText}>
                                        Cancel or change your booking up to{' '}
                                        {displayCancellation(productController?.product?.cancellationPeriod)} prior to
                                        departure.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs="auto" sx={{ mr: 1.5, pt: 0.25 }}>
                                    <UpdateIcon color="primary" />
                                </Grid>
                                <Grid container direction="column" item xs={true}>
                                    <Typography sx={{ fontWeight: 500, mb: 1, fontSize: 18 }}>
                                        Instant Booking
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} className={classes.cancellationtText}>
                                        Book online, with live availability, and instant confirmation.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            pb: { xs: 2, sm: 0, md: 1 },
                            maxWidth: { xs: 1, lg: 'calc(80% + 30px)' },
                            width: { xs: 1, sm: 1 },
                            pt: { xs: 0 },
                            px: { xs: 0, sm: '15px' }
                        }}
                    >
                        <Grid item xs={12}>
                            <Paper
                                variant="outlined"
                                className={clsx(classes.productDesc, classes.descriptionBox)}
                                sx={{
                                    py: { xs: 0, sm: 2 },
                                    px: { xs: 2, sm: 2 }
                                }}
                            >
                                {productController.product?.tagline && (
                                    <Typography variant="h5" style={{ fontWeight: 500, paddingBottom: 20 }}>
                                        {productController.product.tagline}
                                    </Typography>
                                )}
                                <DisplayText text={tour.longDescription} />
                            </Paper>
                        </Grid>
                        {tour.map && (
                            <Grid item xs={12} className={classes.tourMap}>
                                <img src={tour.map} alt={`${tour.name} tour map`} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
