import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material/';
import { map } from 'lodash';
import { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';

export default function TermsAndConditions() {
    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.remove();
        }
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const termsList = [
        'GSL tours are designed for mainland guests. See the following terms relating to cruise ship guests, and island resorts.',
        'Cruise ship passengers are responsible for checking tour times prior to booking, and ensuring that tender times will suit; GSL will not honor refunds or cancellations within 24 hours, if guests cannot travel because of tender time restrictions.',
        'GSL will not honor refunds or cancellations within 24 hours for guests that are staying at Island Resorts, and cannot make their tour because of ferry time restrictions.',
        'Weight restrictions apply on all flight products, and there is a seat limitation of 125kg per person.',
        'Flight products are subject to pilot discretion, weather, air traffic control and aircraft availability.',
        'Prices listed on this site are per person, unless otherwise specified.',
        'Minimum numbers apply for tours to proceed, our staff will contact you if this is likely to affect your booking.',
        'Prices advertised include GST, and applicable State and Federal Goverment fees (yes, they both charge separate fees to access the same locations).',
        "Whitehaven Beach and other locations may be unaccessible in Northerly Winds or adverse conditions. We'll advise when this affects your tour, and our contingency plan suitable to the conditions.",
        'Tours and pricing are subject to change without notice.',
        'Passengers that do not arrive on time for check-on, including scheduled bus pick-ups, may be classed as a no-show. Please allow extra time for parking (if applicable), and plan to arrive 10 minutes before your scheduled check-in time.',
        'A 100% cancellation fee applies for cancellations, no shows, or booking changes within 24 hours of departure.',
        'Tours, or portions of tours, may be cancelled by adverse weather conditions or engineering reasons. GSL Marine will endeavour to reschedule tours, accommodate guests on other tours, or provide alternative options. In the event that guests are not able to travel at all, the fare will be refunded.',
        'Should any one part of a package tour not operate - you will be charged the full RRP price of the tour component(s) completed',
        'Travel agents shall ensure terms and conditions are communicated with clients.',
        'All tours are covered by carriers liability insurance, specific to the prescribed activitiies being undertaken; those policies may not extend to claims where guests are found to be negligent, such as not following directions of crew.',
        'GSL Marine highly recommend that all guest have their own travel insurance, to cover losses such as lost/damaged personal items on tour, injuries caused by negligence, tour delays, illness, and other such scenarios which may cause financial or personal loss outside of prescribed liabilites under company policies. GSL Marine will not provide compensation for losses that are not covered under our relevant policies, such as lost income, or costs incurred by changing travel plans.',
        'GSL Marine will endeavour to achieve on-time performance, but accept no responsibility for missing tour/flight connections as a result of operational delays.',
        'GSL Marine may use photographs of our clients in promotional material, including but not limited to social media, if you do not consent please advise GSL Marine.'
    ];

    return (
        <div>
            <Helmet>
                <title>GSL Marine | Terms &amp; Conditions | Airlie Beach Jet Ski &amp; Whitehaven Beach Tours</title>
                <meta
                    name="description"
                    content="Terms and conditions for travelling on Whitsunday tours with GSL Marine. Tours depart from Airlie Beach, and guests are encourgaged to check our terms &amp; conditions before travel"
                />
            </Helmet>
            <Container
                maxWidth="lg"
                sx={{
                    px: { xs: 2, sm: 4 }
                }}
            >
                <PageTitle
                    title="Terms &amp; Conditions | GSL Marine"
                    tagline="Things to know about your tour in Airlie Beach"
                />
            </Container>
            <Container
                maxWidth="md"
                sx={{
                    px: { xs: 2, sm: 4 }
                }}
            >
                <Grid container sx={{ pb: 3, pt: { xs: 1, sm: 2 } }}>
                    <Grid item xs={12}>
                        <Box>
                            <List>
                                {map(termsList, (term, i) => (
                                    <ListItem key={`term${i + 1}`} sx={{ px: 0, py: 0.75, opacity: 0.8 }}>
                                        <ListItemText>
                                            <Box style={{ display: 'flex' }}>
                                                <Typography style={{ minWidth: 25, textAlign: 'right' }}>
                                                    {i + 1}.
                                                </Typography>
                                                <Typography style={{ marginLeft: 6 }}>{term}</Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
