import { GSLEntities } from '../models/bookings';
import { emptyProduct, Product } from '../models/products';

export const baseProducts: Product[] = [
    {
        ...emptyProduct,
        entity: GSLEntities.MARINE,
        productCode: 'P14SFD',
        tagline: 'Jet Ski Airlie Beach',
        titleSEO: 'The best value Jet Ski tour in Airlie Beach',
        usp: [
            '60 Minute Jet Ski Tour',
            'Self drive your own ski',
            'Suitable for beginners',
            'No licence required',
            '2 Persons may share a ski'
        ],
        tags: [...emptyProduct.tags, 'popular', 'featureTile'],
        sales: [
            {
                startDate: '2024-06-01T00:00:00+1000',
                endDate: '2024-06-28T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Jet Ski',
                        price: 139
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        productCode: 'PSL9MT',
        entity: GSLEntities.MARINE,
        tagline: 'Jet Ski & Scenic Flight Package',
        titleSEO:
            'Jet ski and scenic flight package Airlie Beach. See Heart Reef, Whitehaven Beach, Hill Inlet, and Airlie Beach',
        usp: [
            'Fly & Jet Ski the Whitsundays',
            'Guaranteed Window Seats',
            'See Heart Reef & Hill Inlet',
            '60 minute guided jet ski tour',
            'No licence required'
        ],
        tags: [...emptyProduct.tags, 'popular', 'package', 'featureTile'],
        allowPackage: true,
        packageCode: 'PWGNPK',
        packageEntity: GSLEntities.GSL_WHITSUNDAYS,
        packageFare: {
            label: 'Adult',
            seatsUsed: 1
        },
        packageProductNames: ['Jet Ski', 'Scenic Flight'],
        sales: [
            {
                startDate: '2024-06-01T00:00:00+1000',
                endDate: '2024-06-28T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 439
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        productCode: 'PWGNPK',
        name: 'Reef & Island Scenic Flight',
        tagline: 'Airlie Beach Scenic Flight Tour',
        titleSEO: 'Scenic flight of the Whitsundays and Great Barrier Reef from Airlie Beach',
        usp: [
            'Amazing views of the Reef & Whitsunday Islands',
            'See Heart Reef, Hill Inlet and Whitehaven from above',
            'Guaranteed Window Seats',
            'Regular Departure Times',
            'See all of the Whitsundays'
        ],
        tags: [...emptyProduct.tags, 'other', 'popular', 'featureTile']
    },
    {
        ...emptyProduct,
        entity: GSLEntities.MARINE,
        productCode: 'PXPGQT',
        tagline: 'Airlie Beach direct to Whitehaven Beach',
        titleSEO: 'Whitehaven Beach Tour from Airlie Beach',
        usp: [
            'Best value Whitehaven Beach tour',
            'Travels direct to Whitehaven',
            'Build your own experience',
            'Comfortable & Fast vessel',
            'Suitable for all ages'
        ],
        tags: [...emptyProduct.tags, 'popular', 'featureTile', 'cruise'],
        saleTips: ['Great Value'],
        sales: [
            {
                startDate: '2024-02-01T00:00:00+1000',
                endDate: '2024-03-28T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 119
                    },
                    {
                        label: 'Child 3 -14',
                        price: 79
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        cancellationPeriod: 2,
        name: 'Camira Sailing Adventure',
        productCode: 'PHJTMQ',
        extras: [],
        tagline: 'All-inclusive sailing tour',
        titleSEO: 'Sail on Camira from Airlie Beach to Whitehaven & Hill Inlet',
        excludeFares: ['Senior', 'Youth Student (15 - 17 years)', '1 Adult 1 Child', 'Family (2Ad 2Ch)'],
        usp: [
            'Sail the Whitsunday Islands',
            'Visit Whitehaven Beach & Hill Inlet',
            'Morning Tea, Lunch, & Afternoon Tea included',
            'Beer & Wine included',
            'Snorkel fringing reef in the Whitsundays'
        ],
        tags: [...emptyProduct.tags, 'other', 'cruise'],
        shortDescription: `Harness the wind and sail the azure waters of the Whitsundays onboard the impressive Camira, a speedy 85ft ocean-going catamaran.
            Hike to the lookout over spectacular Hill Inlet, leave some footprints on famous Whitehaven Beach, swim and enjoy a BBQ lunch onboard.
            An all-inclusive Whitsunday experience.`,
        longDescription: `<p>
                Departing daily from Port of Airlie, Camira will take you through the magical Whitsunday Islands, en route to Whitehaven Beach.
                At 85 foot long, Camira is a magnificant sailing catamaran, perfectly suited to gently cruising the beautiful blue waters of the Whitsundays.
            </p>
            <p>
                <b>Your adventure includes:</b>
            </p>
            <ul>
                <li>
                    Scenic sailing adventure through the Whitsunday Islands, to Whitehaven Beach & Hill Inlet
                </li>
                <li>
                    Morning Tea & Afternoon Tea
                </li>
                <li>
                    BBQ lunch, soft drink, and water
                </li>
                <li>
                    Beer and Wine from 11am
                </li>
                <li>
                    Beach activities
                </li>
                <li>
                    Guided walk to Hill Inlet lookout
                </li>
            </ul>`
    },
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        name: 'Great Barrier Reef Adventure',
        cancellationPeriod: 2,
        productCode: 'PDHEYE',
        extras: [],
        tagline: 'Explore the Great Barrier Reef',
        titleSEO: 'Visit the Great Barrier Reef from Airlie Beach.',
        excludeFares: ['Senior', 'Youth Student (15 - 17 years)', '1 Adult 1 Child', 'Family (2Ad 2Ch)'],
        usp: [
            'Cruise to the Great Barrier Reef',
            '4 Hours at Hardy Reef',
            'Morning Tea, Lunch, & Afternoon Tea included',
            'Access to underwater observatory',
            'Snorkel the Great Barrier Reef'
        ],
        tags: [...emptyProduct.tags, 'other', 'cruise'],
        shortDescription: `Cruise to the Great Barrier Reef with Cruise Whitsundays.
            A full day tour from Airlie Beach to Reef World is a great way to experience the Great Barrier Reef.
            Your reef adventure includes lunch, and 4 hours at Hardy Reef.`,
        longDescription: `<p>
                Beyond the Whitsunday Islands, is your destination 'Reef World' - A multi-level reef facility permanently moored at Hardy Reef.
                Reef World offers many ways to explore the reef, including snorkelling, diving, underwater observatory, and semi-submarine.
            </p>
            <p>
                <b>Your adventure includes:</b>
            </p>
            <ul>
                <li>
                    Scenic return cruise through the Whitsundays to the outer reef
                </li>
                <li>
                    4 hours at Hardy Reef
                </li>
                <li>
                    Snorkelling with all gear provided, including wetsuits and stinger suits
                </li>
                <li>
                    Access to underwater observatory
                </li>
                <li>
                    Semi-submarine guided tour
                </li>
                <li>
                    Morning tea, lunch and afternoon tea
                </li>
            </ul>`
    }
];

export const sitemapArray = baseProducts.map((p) => ({
    productCode: p.productCode,
    entity: p.entity,
    name: p?.name || ''
}));

/*

Fly & Ski. Need to create in WAT and add codes.

{
        ...emptyProduct,
        entity: GSLEntities.SEAPLANES,
        productCode: 'P81BF2',
        tagline: 'Package and save, with a Fly & Ski Package',
        titleSEO: 'Travel over two days, with a Scenic Flight in the Whitsundays, and Airlie Beach Jet Ski Tour',
        usp: [
            'Fly over the Whitsundays and Great Barrier Reef',
            'Guaranteed Window Seats',
            '60 Minute Guided Jet Ski Tour in Airlie Beach ',
            'Choose depature times to suit your itinerary',
            'Save $29 compared to the full fares'
        ],
        tags: [...emptyProduct.tags, 'package', 'featureTile'],
        allowPackage: true,
        packageCode: 'P14SFD',
        packageEntity: GSLEntities.MARINE,
        packageFare: {
            label: 'Jet Ski',
            seatsUsed: 1
        },
        packageProductNames: ['Scenic Flight', 'Jet Ski Safari']
    }
}

*/
