import { Box, Skeleton, Slide, SlideProps } from '@mui/material';
import { Suspense } from 'react';
import { useImage } from 'react-image';
import { FleetImage } from '../../pages/FleetPage';

interface FleetImageDisplayProps {
    readonly image: FleetImage;
    readonly height?: number;
    readonly slide?: boolean;
    readonly slideDirection?: string;
}

const Image = ({ image, height }: FleetImageDisplayProps) => {
    const source = useImage({
        srcList: [image.src, image.fallback]
    });

    return (
        <img
            src={source.src}
            alt={image.alt}
            style={{
                objectFit: 'cover',
                height: height || 'unset',
                width: '100%'
            }}
        />
    );
};

export default function FleetImageDisplay({ image, height, slide, slideDirection }: FleetImageDisplayProps) {
    return (
        <Slide in={slide} direction={slideDirection as SlideProps['direction']}>
            <Box sx={{ width: 1, height: 1, objectFit: 'cover' }}>
                <Suspense fallback={<Skeleton variant="rectangular" height={height} width="100%" />}>
                    <Image image={image} height={height} />
                </Suspense>
            </Box>
        </Slide>
    );
}
