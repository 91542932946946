import { Box, Container, Grid, Typography } from '@mui/material';

import { styled } from '@mui/styles';
import axios from 'axios';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import InstagramImageGallery from './InstagramImageGallery';

export default function InstagramImages() {
    const [mediaList, setMediaList] = useState([]);
    const path = process.env.NODE_ENV === 'production' ? 'https://gslwhitsundays.com.au/' : 'http://localhost:5000/';

    const getMediaList = async () => {
        try {
            const res = await axios(`${path}instagram/images?entity=gslMarine`);
            const createList = isArray(res.data)
                ? res.data.map((url, i) => ({
                      url: url,
                      alt: `GSL Marine Whitsunday tour and Whitehaven Beach image no. ${i + 1}`
                  }))
                : [];
            setMediaList(createList);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getMediaList();
    }, []);

    const BodyText = styled(Typography)(() => ({
        fontSize: 16,
        lineHeight: '140%',
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 20,
        paddingTop: 10
    }));

    return (
        <Container maxWidth="lg" sx={{ p: { xs: 0 }}}>
            <Grid
                container
                sx={{
                    margin: 'auto',
                    pt: { xs: 0, sm: 3 },
                    pb: { xs: 0, sm: 3 },
                    justifyContent: 'center'
                }}
            >
                <Grid
                    container
                    sx={{ textAlign: 'center', px: { xs: 0, md: 1 }, py: { xs: 1, md: 0, lg: 1 } }}
                    item
                    xs={12}
                    md={5}
                    lg={6}
                >
                    <Box sx={{ maxWidth: { md: 700 }, width: '100%', height: '100%' }}>
                        <InstagramImageGallery images={mediaList} />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7}
                    lg={6}
                    sx={{
                        py: { xs: 2, sm: 2, md: 0, lg: 2 },
                        px: { xs: 2, sm: 0, md: 2 },
                        justifyContent: { xs: 'unset', md: 'center', lg: 'unset' },
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '700px'
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontSize: { xs: 22, lg: 28 },
                            color: (theme) => theme.palette.primary.dark,
                            lineHeight: '115%',
                            letterSpacing: '-1px',
                            fontWeight: 400,
                            paddingBottom: 1.5
                        }}
                    >
                        Exploring the Whitsundays
                    </Typography>
                    <BodyText>
                        The 74 Islands of The Whitsundays are world-reknown for their natural beauty. Consisting mostly
                        of National Parks, the islands feature amazing landscapes of rainforests, beaches, and unique
                        eco-systems, surrounded by the Great Barrier Reef Marine Park. Beyond the Whitsunday Islands, is
                        the Great Barrier Reef. Approximately 80km offshore, the closest sections of Reef to Airlie
                        Beach are Hardy Reef, Hook Reef, and Bait Reef.
                    </BodyText>
                    <BodyText sx={{ display: { xs: 'block', md: 'none', lg: 'block' } }}>
                            Our Whitehaven Beach Club is the best value Whitehaven Product in the Whitsundays, and you
                            can make the experience yours with combination of optional extras including snorkelling,
                            lunch, or a bushwalk. With the largest variety of products in the
                            Whitsundays, GSL has your bucket list covered!
                    </BodyText>

                    <BodyText>
                        Tours depart daily from Airlie Beach, with a range of experiences in the Whitsundays including
                        Whitehaven Beach, Jet Ski Tours, and the Great Barrier Reef. Come and explore our amazing
                        backyard!
                    </BodyText>
                </Grid>
            </Grid>
        </Container>
    );
}

/*

Collate and shuffle all product images from Product Store

const productController = useContext(ProductsStore);
const mediaList = shuffle(
    productController.products.map((prod) => prod.photos).reduce((acc, photos) => acc.concat(photos))
).map((image) => image.largeURL);

*/

