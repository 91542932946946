import CloseIcon from '@mui/icons-material/Close';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import SubjectIcon from '@mui/icons-material/Subject';
import {
    Button,
    Collapse,
    Container,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme
} from '@mui/material';
import PopupState from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover } from 'material-ui-popup-state/hooks';
import { ShipWheel } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import WideLogo from '../../assets/squareLogo.png';
import { productURL } from '../../utils/functions/common';
import { ProductsStore } from '../../utils/stores/ProductsStore';

const FullWidth = () => {
    const productController = useContext(ProductsStore);
    const theme = useTheme();

    return (
        <Grid container justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Grid container maxWidth="xl" sx={{ p: 2, justifyContent: 'space-between' }}>
                <Grid item sx={{ display: 'flex', alignContent: 'center', pl: 1 }}>
                    <Link to={''}>
                        <img
                            src={WideLogo}
                            alt="GSL Whitsundays Logo"
                            style={{ height: 60, cursor: 'pointer', color: theme.palette.primary.main }}
                        />
                    </Link>
                </Grid>
                <Grid item sx={{ display: 'flex', alignContent: 'center' }}>
                    <Button
                        component={Link}
                        to={'/'}
                        aria-label="menu-home"
                        variant="text"
                        sx={{ width: '120px', fontSize: 15 }}
                    >
                        Home
                    </Button>
                    <PopupState variant="popover" popupId={'tours-popover'}>
                        {(popupState) => (
                            <>
                                <Button
                                    id={'tours'}
                                    aria-label="tours-menu"
                                    variant="text"
                                    sx={{ width: '120px', fontSize: 15 }}
                                    endIcon={popupState.isOpen ? <UpIcon /> : <DownIcon />}
                                    {...bindHover(popupState)}
                                >
                                    Tours
                                </Button>
                                <HoverPopover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center'
                                    }}
                                >
                                    <List sx={{ py: 0 }}>
                                        {productController.products.map((prod) => (
                                            <ListItem key={prod.searchableName} sx={{ p: 0 }}>
                                                <ListItemButton component={Link} to={productURL(prod.name)}>
                                                    <ListItemText>{prod.name}</ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </HoverPopover>
                            </>
                        )}
                    </PopupState>
                    <PopupState variant="popover" popupId={'about-popover'}>
                        {(popupState) => (
                            <>
                                <Button
                                    id={'about'}
                                    aria-label="about-menu"
                                    variant="text"
                                    sx={{ width: '120px', fontSize: 15 }}
                                    endIcon={popupState.isOpen ? <UpIcon /> : <DownIcon />}
                                    {...bindHover(popupState)}
                                >
                                    About
                                </Button>
                                <HoverPopover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center'
                                    }}
                                >
                                    <List sx={{ py: 0 }}>
                                        <ListItem sx={{ p: 0 }}>
                                            <ListItemButton component={Link} to={'/terms-and-conditions'}>
                                                <ListItemText>Terms & Conditions</ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem sx={{ p: 0 }}>
                                            <ListItemButton component={Link} to={'/fleet'}>
                                                <ListItemText primary="Our Fleet" />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </HoverPopover>
                            </>
                        )}
                    </PopupState>
                    <Button
                        component={Link}
                        to={'/contact-us'}
                        aria-label="menu-home"
                        variant="text"
                        sx={{ width: '120px', fontSize: 15 }}
                    >
                        Contact
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const SmallHeader = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tourIconOpen, setTourIconOpen] = useState(false);
    const [aboutIconOpen, setAboutIconOpen] = useState(false);
    const theme = useTheme();
    const productController = useContext(ProductsStore);

    const handleTourList = () => {
        setTourIconOpen(!tourIconOpen);
    };

    const handleAboutList = () => {
        setAboutIconOpen(!aboutIconOpen);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 2, display: { md: 'none' } }}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ pl: 1 }}>
                <Grid item xs="auto">
                    <Link to={''}>
                        <img
                            src={WideLogo}
                            alt="GSL Whitsundays Logo"
                            style={{ height: 50, cursor: 'pointer', color: theme.palette.primary.main }}
                        />
                    </Link>
                </Grid>
                <Grid item xs={true} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Grid item>
                        <IconButton onClick={toggleDrawer(true)} aria-label="Menu Icon" size="large">
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    sx={{
                        '& .MuiDrawer-paper': {
                            minWidth: 400,
                            width: { xs: 1, sm: 'unset' },
                            mindWidth: { xs: 'unset', sm: '400px' }
                        }
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            width: 1,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            pt: 1
                        }}
                        container
                    >
                        <ListItemButton
                            onClick={toggleDrawer(false)}
                            sx={{
                                padding: '0 20px 15px 15px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                width: 1
                            }}
                        >
                            <ListItemIcon>
                                <img
                                    src={WideLogo}
                                    alt="GSL Whitsundays logo"
                                    style={{ height: 55, paddingTop: 5, cursor: 'pointer' }}
                                />
                            </ListItemIcon>
                            <CloseIcon sx={{ opacity: 0.8, mr: 1 }} />
                        </ListItemButton>
                        <ListItemButton
                            component={Link}
                            to={'/'}
                            onClick={toggleDrawer(false)}
                            style={{ padding: '7px 20px' }}
                        >
                            <ListItemIcon>
                                <HomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                        <ListItemButton onClick={handleTourList} style={{ padding: '7px 20px' }}>
                            <ListItemIcon>
                                <DirectionsBoatIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Tours" />
                            {tourIconOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={tourIconOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {productController.products.map((prod) => (
                                    <ListItemButton
                                        key={prod.searchableName}
                                        component={Link}
                                        to={productURL(prod.name)}
                                        onClick={toggleDrawer(false)}
                                        style={{
                                            paddingLeft: 35,
                                            paddingRight: 25,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            opacity: 0.9
                                        }}
                                    >
                                        <ListItemText>
                                            <Grid container>
                                                <Grid item xs="auto">
                                                    •
                                                </Grid>
                                                <Grid item xs={true} style={{ paddingLeft: 10 }}>
                                                    {prod.name}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleAboutList} style={{ padding: '7px 20px' }}>
                            <ListItemIcon>
                                <InfoIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="About Us" />
                            {aboutIconOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={aboutIconOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    component={Link}
                                    to={'/terms-and-conditions'}
                                    onClick={toggleDrawer(false)}
                                    style={{
                                        paddingLeft: 35,
                                        paddingRight: 25,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        opacity: 0.9
                                    }}
                                >
                                    <ListItemIcon>
                                        <SubjectIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Terms &amp; Conditions" />
                                </ListItemButton>
                                <ListItemButton
                                    component={Link}
                                    to={'/fleet'}
                                    onClick={toggleDrawer(false)}
                                    style={{
                                        paddingLeft: 35,
                                        paddingRight: 25,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        opacity: 0.9
                                    }}
                                >
                                    <ListItemIcon>
                                        <ShipWheel color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Our Fleet" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton
                            component={Link}
                            to={'/contact-us'}
                            onClick={toggleDrawer(false)}
                            style={{ padding: '7px 20px' }}
                        >
                            <ListItemIcon>
                                <PhonelinkRingIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Contact Us" />
                        </ListItemButton>
                    </Grid>
                </Drawer>
            </Grid>
        </Container>
    );
};

export default function Header() {
    return (
        <>
            <SmallHeader />
            <FullWidth />
        </>
    );
}
