import React from 'react';
import CalendarDateSel from './CalendarDateSel';
import SessionSelect from './SessionSelect';
import { Box, Grid } from '@mui/material';
import { commonStyles } from './commonStyles';


export default function PackageDateTimeSelect() {
    const classes = commonStyles().classes;

    return (
        <>
            <Grid item xs={12} md="auto" lg={6}>
                <Box className={classes.dateSelect}>
                    <CalendarDateSel isPackage={true} />
                </Box>
            </Grid>
            <Grid item xs={12} md={true} lg={6}>
                <Box className={classes.timeSelect}>
                    <SessionSelect isPackage={true} />
                </Box>
            </Grid>
        </>
    );
}
