import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CallIcon from '@mui/icons-material/Call';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Facebook from 'mdi-material-ui/Facebook';
import Instagram from 'mdi-material-ui/Instagram';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme) => ({
    contactPaper: {
        margin: '30px 20px 0',
        '& h5': {
            marginBottom: 5
        },
        backgroundColor: 'inherit'
    },
    contactPaperDark: {
        backgroundColor: 'rgba(0,0,0,0)',
        color: 'white',
        '& h5': {
            color: 'white'
        }
    },
    contactInfo: {
        position: 'relative',
        padding: 0,
        marginBottom: 10,
        textAlign: 'left',
        '& .MuiListItem-gutters': {
            padding: 0,
            marginBottom: 5
        },
        '& ListItemText': {
            fontWeight: '300'
        },
        '& :hover': {
            color: theme.palette.primary.main,
            transition: 'all, .2s'
        }
    },
    contactInfoDark: {
        color: theme.palette.secondary.contrastText,
        '& .MuiSvgIcon-root': {
            color: theme.palette.common.white
        }
    },
    socialIcons: {
        '& .MuiSvgIcon-root': {
            fontSize: 32,
            marginRight: 25
        },
        '& :hover': {
            cursor: 'pointer'
        }
    }
}));

interface ContactProps {
    location: string;
    phone?: string;
    email?: string;
    hideSocial?: boolean;
    darkBackground?: boolean;
}

export default function ContactDetails({ location, phone, email, darkBackground, hideSocial }: ContactProps) {
    const classes = useStyles().classes;
    const theme = useTheme();

    const ListItemLink = (props: any) => {
        return <ListItem button component="a" {...props} />;
    };

    return (
        <Paper
            elevation={0}
            className={clsx({
                [classes.contactPaper]: true,
                [classes.contactPaperDark]: darkBackground
            })}
        >
            <Typography variant="h5" style={{ marginBottom: 20 }}>
                {location}
            </Typography>
            <List style={{ paddingLeft: 15 }}>
                {phone && (
                    <ListItem
                        className={clsx({
                            [classes.contactInfo]: true,
                            [classes.contactInfoDark]: darkBackground
                        })}
                    >
                        <ListItemLink href={`tel:${phone}`}>
                            <ListItemIcon>
                                <CallIcon />
                            </ListItemIcon>
                            <ListItemText
                                style={{ color: darkBackground ? 'white' : theme.palette.text.soft }}
                                primary={phone}
                            />
                        </ListItemLink>
                    </ListItem>
                )}
                {email && (
                    <ListItem
                        className={clsx({
                            [classes.contactInfo]: true,
                            [classes.contactInfoDark]: darkBackground
                        })}
                    >
                        <ListItemLink href={`mailto:${email}`}>
                            <ListItemIcon>
                                <EmailOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                style={{ color: darkBackground ? 'white' : theme.palette.text.soft }}
                                primary={email}
                            />
                        </ListItemLink>
                    </ListItem>
                )}
            </List>
            {!hideSocial && (
                <Grid container>
                    <Grid item xs={12} style={{ paddingLeft: 15 }}>
                        <div className={classes.socialIcons}>
                            <a href="https://facebook.com/gslmarine" aria-label="Facebook Link">
                                <Facebook style={{ color: darkBackground ? 'white' : theme.palette.text.soft }} />
                            </a>
                            <a href="https://instagram.com/gsl_marine/" aria-label="Instagram Link">
                                <Instagram style={{ color: darkBackground ? 'white' : theme.palette.text.soft }} />
                            </a>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
}
