import { Box, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { Product } from '../../../utils/models/products';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import { commonStyles } from './commonStyles';

export interface PickUpListProps {
    product: Product;
}

interface PackageSelectProps {
    readonly isPackage?: boolean;
}

export default function SelectPickUp({ isPackage }: PackageSelectProps) {
    const classes = commonStyles().classes;
    const theme = useTheme();
    const isMounted = IsMountedWrapper();
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const product = isPackage ? productController.packageProduct.data : productController.product;
    const booking = isPackage ? bookingController.packageBooking.data : bookingController.booking;
    const [pickUpExists, setPickUpExists] = useState(
        product?.pickupList && product.pickupList.slice(1, product.pickupList.length).length > 0
    );
    const needPickUp = isPackage ? bookingController.needPackagePickUp.data : bookingController.needPickUp.data;
    const locationSelected = booking?.tour?.pickUpLocation !== product?.pickupList?.[0].locationName;
    const locationSelectedRef = useRef<boolean>();
    locationSelectedRef.current = locationSelected;

    const checkPickUp = () => {
        if (!locationSelectedRef.current) {
            !isPackage
                ? product.pickupList[0].locationName &&
                  bookingController.changePickUp(null, product.pickupList[0].locationName)
                : product.pickupList[0].locationName &&
                  bookingController.changePackagePickUp(null, product.pickupList[0].locationName);
        }
    };

    useEffect(() => {
        const pickUpStatus = booking?.tour?.pickUpLocation !== product?.pickupList?.[0].locationName;
        !isPackage
            ? bookingController.needPickUp.setData(pickUpStatus, isMounted)
            : bookingController.needPackagePickUp.setData(pickUpStatus, isMounted);
        return () => {
            checkPickUp();
        };
    }, []);

    useEffect(() => {
        setPickUpExists(product?.pickupList && product.pickupList.slice(1, product.pickupList.length).length > 0);
    }, [product?.pickupList]);

    useEffect(() => {
        locationSelectedRef.current = locationSelected;
    }, [booking.tour.pickUpLocation, booking.packageBooking?.tour?.pickUpLocation]);

    const PickUpList = ({ product }: PickUpListProps) => {
        return (
            <>
                {product.pickupList.slice(1, product.pickupList.length).map((location, i) => (
                    <option key={`$loc${i}`} value={location.locationName}>
                        {location.locationName}
                    </option>
                ))}
            </>
        );
    };

    return (
        <>
            {pickUpExists && (
                <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs="auto">
                        <FormGroup row className={classes.pickUpCheckBox}>
                            <Typography className={classes.subtitle} style={{ padding: '0 10px 0 0' }}>
                                Need a Ride?
                            </Typography>
                            <Switch
                                color="primary"
                                checked={needPickUp}
                                onChange={
                                    isPackage ? bookingController.togglePackagePickUp : bookingController.togglePickUp
                                }
                                name="selectPickUp"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={true} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography className={classes.checkLabel}>Check here to select a pick-up</Typography>
                    </Grid>

                    <Typography
                        component="div"
                        className={clsx(classes.fieldInputSection, classes.checkLabel)}
                        style={{ opacity: needPickUp ? 1 : 0.4, marginTop: 10 }}
                    >
                        <Box display="inline" style={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                            Oooh yeah!
                        </Box>{' '}
                        Your booking includes a free transfer service. Just select the location below, and we&apos;ll
                        give you a lift to and from your tour.
                    </Typography>
                </Grid>
            )}

            {pickUpExists && (
                <Grid item xs={12} className={classes.fieldInputSection}>
                    <TextField
                        className={classes.fieldInputItem}
                        select
                        label="Select Location"
                        value={booking.tour.pickUpLocation || ''}
                        onChange={isPackage ? bookingController.changePackagePickUp : bookingController.changePickUp}
                        disabled={!needPickUp}
                        name="pickupLocation"
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="" />
                        <PickUpList product={product} />
                    </TextField>
                </Grid>
            )}
            {pickUpExists ? (
                <Grid item xs={12} className={classes.fieldInputSection}>
                    <Typography variant="body1" className={classes.locationTitle} style={{ paddingBottom: 5 }}>
                        How Does This Work?
                    </Typography>
                    <Box component="span" style={{ display: 'flex', paddingBottom: 5 }}>
                        <>
                            {product.pickupList[0].locationName !== booking.tour.pickUpLocation && (
                                <Typography
                                    variant="body1"
                                    className={classes.location}
                                    style={{ paddingRight: 7, whiteSpace: 'nowrap' }}
                                >
                                    Be Here:
                                </Typography>
                            )}
                            <Typography variant="body1" className={classes.location}>
                                {booking.tour.pickUpLocation || 'TBA'}
                            </Typography>
                        </>
                    </Box>
                    {product.pickupList[0].locationName !== booking.tour.pickUpLocation ? (
                        <Typography variant="body1" className={classes.location} style={{ paddingBottom: 0 }}>
                            At this Time: {bookingController.calcDisplayTime(product, isPackage) || 'TBA'}
                        </Typography>
                    ) : (
                        <Typography variant="body1" className={classes.location} style={{ paddingBottom: 0 }}>
                            Check In By: {bookingController.calcDisplayTime(product, isPackage) || 'TBA'}
                        </Typography>
                    )}
                </Grid>
            ) : (
                <Grid item xs={12} className={classes.fieldInputSection}>
                    <Typography
                        variant="body1"
                        className={classes.subtitle}
                        style={{ paddingBottom: 5, fontWeight: 400 }}
                    >
                        {booking.tour.pickUpLocation}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.location}
                        style={{ paddingBottom: 20, fontWeight: 300 }}
                    >
                        {product?.pickupList &&
                            product.pickupList.find((loc) => loc.locationName === booking.tour.pickUpLocation)
                                .additionalInstructions}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.subtitle}
                        style={{ paddingBottom: 20, fontWeight: 400 }}
                    >
                        Check In By: {bookingController.calcDisplayTime(product, isPackage) || 'TBA'}
                    </Typography>
                    <Typography variant="body1" className={classes.location} style={{ paddingBottom: 0 }}>
                        That&apos;s it, we&apos;ll see you there! This information will also be included with your
                        booking confirmation.
                    </Typography>
                </Grid>
            )}
        </>
    );
}
