import { Box, Container, Grid, ListItemButton, ListItemText, Paper, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { productURL } from '../../utils/functions/common';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { baseProducts } from '../../utils/stores/productCodes';
import ContactDetails from '../coreLayout/ContactDetails';

export default function Footer() {
    const productController = useContext(ProductsStore);

    const linkButtons = [
        {
            text: 'Terms & Conditions',
            link: '/termsandconditions'
        }
    ];

    const productLinks =
        !productController.isLoading &&
        baseProducts
            .filter((p) => p.tags.includes('featureTile'))
            .map((p) => {
                const prod = productController.products.find((prod) => prod.productCode === p.productCode);
                const res = prod && { text: prod.name, link: productURL(prod.name) };
                return res;
            })
            .filter((r) => r?.link);

    const ListItemLink = (props: any) => {
        return <ListItemButton component="a" {...props} />;
    };

    const ExternalLink = styled(ListItemText)(() => ({
        '& .MuiTypography-body1': {
            fontWeight: 400,
            fontSize: '1.1rem',
            color: 'white'
        }
    }));

    return (
        <Box
            sx={{
                pt: 1,
                pb: 1,
                backgroundColor: (theme) => theme.palette.primary.dark
            }}
        >
            <Container maxWidth="lg" sx={{ pl: 0, pr: 0 }}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ m: '30px 20px 0', backgroundColor: 'inherit' }}>
                            <Typography variant="h5" sx={{ marginBottom: 2, color: 'white' }}>
                                Popular Links
                            </Typography>
                            <Box sx={{ paddingTop: 1, PaddingBottom: 1, display: 'flex', flexWrap: 'wrap' }}>
                                {linkButtons.concat(productLinks).map((link, i) => (
                                    <Typography
                                        display="inline"
                                        sx={{
                                            background: (theme) => theme.palette.secondary.light,
                                            color: (theme) => theme.palette.primary.dark,
                                            borderRadius: 3,
                                            fontWeight: 400,

                                            width: 'fit-content',
                                            marginRight: 1,
                                            marginBottom: 1,
                                            px: 1.5,
                                            py: 1.2,

                                            textDecoration: 'none'
                                        }}
                                        component={Link}
                                        to={link.link || '/'}
                                        key={`button${i}`}
                                    >
                                        {link.text}
                                    </Typography>
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <ContactDetails
                            location="Contact Us"
                            phone="07 4015 4191"
                            email="sales@gslwhitsundays.com.au"
                            darkBackground
                        />
                    </Grid>
                    <Grid container item xs={12} sx={{ paddingTop: 4, paddingLeft: 2, paddingBottom: 2 }}>
                        <Typography sx={{ color: 'white' }}>
                            {'Copyright © '} GSL Marine {new Date().getFullYear()}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
