import { Box, Typography } from '@mui/material';

export default function PageNotFound() {
    return (
        <Box
            sx={{
                height: '70vh',
                textAlign: 'center',
                color: 'primary.main',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <Typography variant="h4" color="primary">
                404 Error | Page Not Found
            </Typography>
            <Typography>Try using the menu to navigate to our other pages.</Typography>
        </Box>
    );
}
