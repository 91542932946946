import { Box, Container, Grid, Hidden, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import PlaceIcon from '@mui/icons-material/Place';
import ScheduleIcon from '@mui/icons-material/Schedule';
import moment from 'moment';
import { useContext, useLayoutEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SummaryContent } from '../components/bookings/BookingFormSummary';
import PageTitle from '../components/coreLayout/PageTitle';
import { amountUiStr } from '../utils/functions/common';
import { BookingStore } from '../utils/stores/BookingStore';
import { baseProducts } from '../utils/stores/productCodes';
import { ProductsStore } from '../utils/stores/ProductsStore';
import { makeStyles } from 'tss-react/mui';

const confirmationStyles = makeStyles()((theme) => ({
    container: {
        [theme.breakpoints.only('sm')]: {
            maxWidth: 700
        },
        [theme.breakpoints.only('xs')]: {
            padding: '0 11px'
        },
        marginTop: 10
    },
    summarySection: {
        padding: 15,
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            padding: '0 15px'
        }
    },
    itemText: {
        fontSize: 17,
        lineHeight: '135%',
        fontWeight: 300
    },
    qtyText: {
        fontSize: 15,
        lineHeight: '110%',
        fontWeight: 300,
        marginBottom: 12,
        opacity: 0.65
    },
    divider: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D9DBE9',
        width: '100%',
        height: 0,
        marginTop: 20,
        [theme.breakpoints.down('lg')]: {
            marginTop: 5
        }
    }
}));

const PaymentDetails = () => {
    const bookingController = useContext(BookingStore);
    const booking = bookingController?.completedBookings?.data?.booking;
    const packageBooking = bookingController?.completedBookings?.data?.package;
    const classes = confirmationStyles().classes;
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Paper
            elevation={0}
            style={{
                paddingTop: 20,
                paddingBottom: 15,
                width: '100%',
                paddingRight: !smallScreen && 20
            }}
        >
            <Typography
                variant="h6"
                style={{ paddingBottom: 10, fontWeight: 400, opacity: 0.9, fontSize: smallScreen && 18 }}
            >
                Your Payment
            </Typography>
            <Typography
                component="div"
                className={classes.itemText}
                style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.8 }}
            >
                Date/Time: <Box display="inline">{moment(booking.payment.date).format('D MMM YYYY, h:mm a')}</Box>
            </Typography>
            <Typography
                component="div"
                className={classes.itemText}
                style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.8 }}
            >
                Merchant Ref No:
                <Box display="inline">{booking.payment.paymentId}</Box>
            </Typography>
            <Typography
                component="div"
                className={classes.itemText}
                style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.8 }}
            >
                Amount:
                <Box display="inline">$ {amountUiStr(booking.payment.amount)}</Box>
            </Typography>
        </Paper>
    );
};

export default function BookingConfirmationPage() {
    const bookingController = useContext(BookingStore);
    const booking = bookingController?.completedBookings?.data.booking;
    const packageBooking = bookingController?.completedBookings?.data.package;
    const productController = useContext(ProductsStore);
    const { ref } = useParams() as any;
    const classes = confirmationStyles().classes;
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <>
            <Container maxWidth="lg" style={{ padding: xsScreen && '0 6px' }}>
                <PageTitle title="Tour Confirmation" tagline="Get ready to explore the Whitsundays" />
            </Container>
            {ref === booking?.bookingResponse?.refNum ? (
                <Container maxWidth="md" className={classes.container}>
                    <Grid container justifyContent="center" style={{ paddingTop: 40, paddingBottom: 40 }}>
                        <Grid item xs={12} md={6} style={{ padding: '0 15px' }}>
                            <Grid container>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="primary"
                                    style={{
                                        paddingBottom: 10,
                                        fontWeight: 400,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    Congratulations{' '}
                                    <Box display="inline">
                                        <InsertEmoticonIcon
                                            fontSize={smallScreen ? 'medium' : 'large'}
                                            color="primary"
                                            style={{
                                                marginLeft: 15,
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: 2
                                            }}
                                        />
                                    </Box>
                                </Typography>
                                <Typography className={classes.itemText} style={{ opacity: 0.8 }}>
                                    You&apos;re all set to travel. From now on, your booking is subject to our terms and
                                    conditions, including our 24 hour cancellation policy. You&apos;re welcome to make
                                    changes to your booking at any time, unless it&apos;s within 24 hours of departure.
                                    So if your travel plans are changing, please get in contact with us, and we&apos;ll
                                    make sure your booking is up to date.
                                </Typography>
                                <Grid container direction="column">
                                    <Typography
                                        variant="h6"
                                        style={{
                                            paddingBottom: 10,
                                            paddingTop: 15,
                                            fontWeight: 400,
                                            opacity: 0.9,
                                            fontSize: smallScreen && 18
                                        }}
                                    >
                                        Booking Reference
                                    </Typography>
                                    <Typography
                                        component="div"
                                        className={classes.itemText}
                                        style={{ display: 'flex', opacity: 0.8, alignItems: 'center' }}
                                    >
                                        <ConfirmationNumberIcon color="primary" />
                                        <Box style={{ marginLeft: 15 }} display="inline">
                                            {booking.bookingResponse.refNum}
                                        </Box>
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            paddingBottom: 10,
                                            paddingTop: 15,
                                            fontWeight: 400,
                                            opacity: 0.9,
                                            fontSize: smallScreen && 18
                                        }}
                                    >
                                        When &amp; Where
                                    </Typography>
                                    <Box style={{ paddingLeft: booking.isPackage ? 10 : 0 }}>
                                        {booking.isPackage && (
                                            <Typography
                                                style={{
                                                    color: theme.palette.text.soft,
                                                    marginBottom: 10,
                                                    marginTop: 5
                                                }}
                                            >
                                                {booking.packageProductNames[0]}
                                            </Typography>
                                        )}
                                        <Typography
                                            component="div"
                                            className={classes.itemText}
                                            style={{ display: 'flex', opacity: 0.8, alignItems: 'center' }}
                                        >
                                            <ScheduleIcon color="primary" />
                                            <Box style={{ marginLeft: 15 }} display="inline">
                                                {moment(booking.tour.pickUpTime)
                                                    .utcOffset(10)
                                                    .format('h:mm a, D MMM YYYY')}
                                            </Box>
                                        </Typography>
                                        <Typography
                                            component="div"
                                            className={classes.itemText}
                                            style={{
                                                display: 'flex',
                                                opacity: 0.8,
                                                paddingTop: 10,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <PlaceIcon color="primary" />
                                            <Box display="inline" style={{ marginLeft: 15 }}>
                                                {booking.tour.pickUpLocation}
                                            </Box>
                                        </Typography>
                                    </Box>
                                    {booking.isPackage && (
                                        <Box style={{ paddingLeft: 10 }}>
                                            <Typography
                                                style={{
                                                    color: theme.palette.text.soft,
                                                    marginBottom: 10,
                                                    marginTop: 20
                                                }}
                                            >
                                                {booking.packageProductNames[1]}
                                            </Typography>
                                            <Typography
                                                component="div"
                                                className={classes.itemText}
                                                style={{ display: 'flex', opacity: 0.8 }}
                                            >
                                                <ScheduleIcon color="primary" />
                                                <Box style={{ marginLeft: 15 }} display="inline">
                                                    {moment(packageBooking.tour.pickUpTime)
                                                        .utcOffset(10)
                                                        .format('h:mm a, D MMM YYYY')}
                                                </Box>
                                            </Typography>
                                            <Typography
                                                component="div"
                                                className={classes.itemText}
                                                style={{ display: 'flex', opacity: 0.8, paddingTop: 10 }}
                                            >
                                                <PlaceIcon color="primary" />
                                                <Box display="inline" style={{ marginLeft: 15 }}>
                                                    {packageBooking.tour.pickUpLocation}
                                                </Box>
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                                <PaymentDetails />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: !smallScreen && '0 15px' }}>
                            <Paper className={classes.summarySection} elevation={smallScreen ? 0 : 1}>
                                <Hidden only="xs">
                                    <Grid container>
                                        <Grid item xs="auto">
                                            {productController.isLoading ? (
                                                <Box
                                                    style={{
                                                        overflow: 'hidden',
                                                        borderRadius: 10,
                                                        height: 100,
                                                        width: 100,
                                                        marginRight: 15,
                                                        marginBottom: largeScreen ? 0 : 10
                                                    }}
                                                >
                                                    <Skeleton variant="rectangular" height="100%" width="100%" />
                                                </Box>
                                            ) : (
                                                <img
                                                    src={
                                                        productController?.products.find(
                                                            (prod) => prod.productCode === booking.tour.productCode
                                                        ).photos[0].thumbnailURL
                                                    }
                                                    alt="product booking thumbnail"
                                                    style={{
                                                        objectFit: 'cover',
                                                        height: 100,
                                                        width: 100,
                                                        borderRadius: 10,
                                                        marginRight: 15,
                                                        marginBottom: largeScreen ? 0 : 10
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={true}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: 23,
                                                            lineHeight: '115%',
                                                            paddingBottom: 10
                                                        }}
                                                    >
                                                        {booking.tour.productName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        className={classes.itemText}
                                                        style={{
                                                            fontSize: 16,
                                                            marginBottom: xsScreen ? 10 : 0,
                                                            opacity: 0.9
                                                        }}
                                                    >
                                                        {
                                                            baseProducts.find(
                                                                (prod) => prod.productCode === booking.tour.productCode
                                                            ).tagline
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.divider} />
                                </Hidden>
                                <SummaryContent
                                    isConfirmation={true}
                                    booking={bookingController.completedBookings.data.booking}
                                    packageBooking={bookingController.completedBookings.data?.package || undefined}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                component="div"
                                sx={{ py: 3.5, px: 2, fontWeight: 300, fontSize: 14, opacity: 0.8 }}
                            >
                                Please note that your browser will cache this data, and if your booking details have
                                changed at any time since the booking was made, the details shown here may not be
                                correct. Whenever there&apos;s changes to your booking, our team will send a new
                                confirmation via email. Your ticket is subject to our
                                <Box display="inline">
                                    {' '}
                                    <Link to={'/terms-and-conditions'}>terms &amp; conditions.</Link>
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <Container maxWidth="lg">
                    <Grid
                        container
                        justifyContent="center"
                        direction="column"
                        alignItems="center"
                        style={{ height: '70vh', textAlign: 'center' }}
                    >
                        <Typography variant="h4" color="primary">
                            Error | No booking Information
                        </Typography>
                        <Typography color="primary">Try using the menu to navigate to our other pages.</Typography>
                    </Grid>
                </Container>
            )}
        </>
    );
}