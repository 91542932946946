import { Container, Grid } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from 'tss-react/mui';
import ContactDetails from '../components/coreLayout/ContactDetails';
import PageTitle from '../components/coreLayout/PageTitle';

const useStyles = makeStyles()((theme) => ({
    containerPadding: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    mapBox: {
        width: '100%',
        height: 450,
        padding: 20,
        [theme.breakpoints.only('md')]: {
            height: 400
        },
        [theme.breakpoints.only('sm')]: {
            height: 500
        },
        [theme.breakpoints.only('xs')]: {
            height: 350
        }
    }
}));

export default function Contact() {
    const { classes } = useStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.remove();
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>GSL Marine - Airlie Beach | Contact Us</title>
                <meta
                    name="description"
                    content="Contact GSL Marine | Information about Whitehaven Beach and Jet Ski Tours in Airlie Beach."
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle title="Contact Us" tagline="Need help with Airlie Beach tour bookings?" />
            </Container>
            <Container
                maxWidth="lg"
                sx={{
                    pl: { xs: 1, sm: 0 },
                    pr: { xs: 1, sm: 0 },
                    pb: { xs: 1, sm: 2, md: 3 }
                }}
            >
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <ContactDetails
                            location="Contact Our Office"
                            phone="07 4015 4191"
                            email="sales@gslwhitsundays.com.au"
                            hideSocial
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
